<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-layout style="height:100%;background:#fff;padding:0 20px;">
                        <a-layout-header style="height:auto;background:#fff;padding:16px 0 ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:0 20px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="类型">
                                                    <a-radio-group default-value="OperationType" v-model="OperationType" @change="QueryData">
                                                        <a-radio-button value="">全部</a-radio-button>
                                                        <a-radio-button value="0">转账</a-radio-button>
                                                        <a-radio-button value="1">异常返还</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="方式">
                                                    <a-radio-group default-value="WithdrawalType" v-model="WithdrawalType" @change="QueryData">
                                                        <a-radio-button value="">全部</a-radio-button>
                                                        <a-radio-button value="0">线上</a-radio-button>
                                                        <a-radio-button value="1">线下</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="状态">
                                                    <a-radio-group default-value="AbnormalState" v-model="AbnormalState" @change="QueryData">
                                                        <a-radio-button value="">全部</a-radio-button>
                                                        <a-radio-button value="0">无异常</a-radio-button>
                                                        <a-radio-button value="1">有异常</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <a-form-item label="" style="margin-bottom:0px;" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="异常信息">
                                                    <a-input placeholder="输入异常信息" v-model="AbnormalMessage" :value="AbnormalMessage" style="width:278px;" />
                                                </a-form-item>

                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>

                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table :columns="columns" rowKey="ID" :data-source="Logs" :pagination="pagination" @change="pagerChange">
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>

        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import util from "@/Plugin/util.js";
    import http from "@/Plugin/Http.js";
    export default {
        name: "LogsList",
        data() {
            return {
                Logs: [],
                LogsList: [],
                columns: [
                    {
                        title: "操作人",
                        dataIndex: "OperatorName",
                        width: 200
                    },
                    {
                        title: "金额",
                        width: 200,
                        customRender: (text, item) => {
                            return util.ToFixed(item.WithdrawalMoney);
                        }
                    },
                    {
                        title: "方式",
                        dataIndex: "WithdrawalTypeName",
                        width: 200
                    },
                    {
                        title: "状态",
                        dataIndex: "AbnormalStateName",
                        width: 200,
                    },
                    {
                        title: "类型",
                        dataIndex: "OperationTypeName",
                        width: 200,
                    },
                    {
                        title: "异常信息",
                        dataIndex: "AbnormalMessage",
                        width: 200,
                    },
                    {
                        title: "日期",
                        dataIndex: "CreateTime",
                        width: 200,
                    },
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 1 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                OperationType: "",
                WithdrawalType: "",
                AbnormalState: "",
                AbnormalMessage: ""
            };
        },
        methods: {
            //查询事件
            QueryData() {
                this.loadData();
            },
            //页面跳转事件
            onPaginationChange(e) {
                this.pagination.current = e
                this.loadData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },
            loadData() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/AccountLogList",
                    data: {
                        DetailID: this.$route.query.id,
                        AbnormalMessage: self.AbnormalMessage,
                        OperationType: self.OperationType,
                        WithdrawalType: self.WithdrawalType,
                        AbnormalState: self.AbnormalState,
                        PageIndex: self.pagination.current
                    },
                    OnSuccess: function (res) {
                        self.Logs = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                        if (res.data != []) {
                            res.data.Data.forEach(element => {
                                element.CreateTime = util.TimeStamptoDateTime(element.CreateTime, 'yyyy-MM-dd hh:mm:ss')
                            });
                        }
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>